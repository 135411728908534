import React from 'react';

import { Helmet } from 'react-helmet-async';

export const Seo = ({ title, description, type, name, favIcoAppleTouchIcon, favIco32, favIco16, favIcoTabbed }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <link rel="apple-touch-icon" sizes="180x180" href={favIcoAppleTouchIcon} data-react-helmet="true"/>
      <link rel="icon" type="image/png" sizes="32x32" href={favIco32} data-react-helmet="true"/>
      <link rel="icon" type="image/png" sizes="16x16" href={favIco16}  data-react-helmet="true"/>
      <link rel="mask-icon" href={favIcoTabbed} color="#000000" data-react-helmet="true"/>
    </Helmet>
  );
};