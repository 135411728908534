import { Fragment } from "react";
import { Seo } from "./SEO";
import favicoSafariPinnedTab  from "../assets/images/favico/safari-pinned-tab.svg";
import faviconAppleTouchIcon  from "../assets/images/favico/apple-touch-icon.png";
import favicon32  from "../assets/images/favico/favicon-32x32.png";
import favicon16  from "../assets/images/favico/favicon-16x16.png";


const SEOHeader = () => {
  return (
    <Fragment>
      <Seo
        title="mitig mookozh"
        description="Explore vibrant Canadian Indigenous art: creative, colorful, layered – an artful journey of light and depth."
        type="website"
        name="mitig test"
        favIcoAppleTouchIcon={faviconAppleTouchIcon}
        favIco32={favicon32}
        favIco16={favicon16}
        favIcoTabbed={favicoSafariPinnedTab}
      />
    </Fragment>
  );
};

const IntroText = () => {
  return (
    <Fragment>
      <p className="fade-in">
        Website is currently under construction. We're working hard to bring you
        an amazing experience. Stay tuned!
      </p>
    </Fragment>
  );
};

const IntroImage = () => {
  return (
    <Fragment>
      <div className="logo fade-in"></div>
    </Fragment>
  );
};

const Intro = () => {
  return (
    <Fragment>
      <SEOHeader />
      <SEOHeader />
      <div className="container">
        <IntroImage />
        <IntroText />
      </div>
    </Fragment>
  );
};

export default Intro;
