import React from "react";
import logo from "./logo.svg";
import "./App.css";

import Maintenance from "./components/Maintenance.js";
import IntroAama from "./components/Staging_Aama.js";

import "./App.css";
import customcss from "./styles/custom.css";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes as Switch, // instead of "Switch"
  Route,
} from "react-router-dom";
// importing the provider
import { HelmetProvider } from 'react-helmet-async';

// If you are using react-helmet-async on server side
const helmetContext = {};

function App() {
  // window.history.pushState({}, null, "/");
  return (
    <div className="App">
      <HelmetProvider context={helmetContext}>
        <Router>
          <Switch>
            <Route exact path="/" element={<Maintenance />} />
            <Route exact path="/aama" element={<IntroAama />} />
          </Switch>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
