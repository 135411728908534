import { Fragment } from "react";
import { Seo } from "./SEO";

const SEOHeaderAama = () => {
  return (
    <Fragment>
      <Seo
        title="Aama Nepalese Cuisine"
        description="Vibrant Nepalese cuisine in the heart of Canada"
        type="website"
        name="mitig test"
      />
    </Fragment>
  );
};

const IntroAama = () => {
  return (
    <Fragment>
      <SEOHeaderAama />
      <div className="container">
        <p>AamaVc</p>
      </div>
    </Fragment>
  );
};

export default IntroAama;
